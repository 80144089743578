<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="6">
        <my-card :isloading="cardloading" :title="code">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :filter-id="idBranchArray" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12">
                <form-date :disabled="!cP(99)" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <form-textarea :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col v-if="$route.params.id" sm="12" md="4">
        <my-card title="Informasi User">
          <template slot="body">
            <table class="w-100">
              <tbody>
                <tr>
                  <td class="font-weight-bold">Di Input Oleh :</td>
                  <td class="text-right">{{inputInfo.user_create}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Update Oleh :</td>
                  <td class="text-right">{{inputInfo.user_update}}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div>
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="2">
                  <!-- <form-select size="sm" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.id_item" :label="`Item ke ${i+1}`"></form-select> -->
                  <form-select-new :clearable="false" size="sm" :ref="`itemSelect-${i}`" url="v1/item_select2" @option:selected="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.item" :label="`Item ke ${i+1}`"></form-select-new>
                </b-col>
                <b-col sm="12" md="2">
                  <!-- <form-select size="sm" :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_item}" @value-changed="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn"></form-select> -->
                  <form-select-new :clearable="false" size="sm" :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_item}" @option:selected="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.unit" label="Stn"></form-select-new>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true, minimal:[0] }" @keyup="calculateRowTotal(i)" size="sm" v-model="tr.qty_sale" label="Qty Penjualan"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true, minimal:[0] }" @keyup="calculateRowTotal(i)" size="sm" v-model="tr.qty_waste" label="Qty Waste"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true, minimal:[0] }" @keyup="calculateRowTotal(i)" size="sm" v-model="tr.qty_employee" label="Qty u/ Karyawan"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true, minimal:[0] }" @keyup="calculateRowTotal(i)" size="sm" v-model="tr.qty_other" label="Qty Lain-lain"></form-input-number>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true }" disabled size="sm" :value="tr.qty_total" label="Qty Total"></form-input-number>
                </b-col>
                <b-col sm="12" md="2">
                  <form-input size="sm" :rules="{ required: (tr.qty_waste>0||tr.qty_employee>0||tr.qty_other>0) }" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col>
                  <b-button v-if="i>0" class="mt-2" block variant="danger" @click="deleteRow(i)" size="sm">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" md="2" class="mt-2" order-sm="1" order-md="0">
                <b-button block variant="primary" @click="addRow">
                  <feather-icon icon="PlusIcon"></feather-icon> Tambah Detail
                </b-button>
              </b-col>
              <b-col sm="12" offset-md="7" md="2">
                <b-row>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalSale" label="Total Penjualan" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalWaste" label="Total Waste" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalEmployee" label="Total u/ Karyawan" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalOther" label="Total u/ Lain-lain" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <hr>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="grandTotal" label="Grand Total" :rules="{required: true}"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="mt-4">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'stockouts'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectNew from '@/my-components/FormSelectNew.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton} from 'bootstrap-vue'
import _ from 'lodash'
import utils from './utils'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BButton,
    FormSelectNew
  },
  data(){
    return {
      utils:utils,
      lodash: _,
      isloading: false,
      cardloading: false,
      id: this.$route.params.id||null,
      id_branch: null,
      branch_name: "",
      date: this.$moment().format(),
      note: "",
      code:"",
      details : [],
      inputInfo: {},
      branch: null
    }
  },
  computed:{
    totalSale(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty_sale)
      }
      return total
    },
    totalWaste(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty_waste)
      }
      return total
    },
    totalEmployee(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty_employee)
      }
      return total
    },
    totalOther(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty_other)
      }
      return total
    },
    grandTotal(){
      return this.totalSale+this.totalWaste+this.totalEmployee+this.totalOther
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'stockouts/store',
      dispatchShow: 'stockouts/show',
      dispatchUpdate: 'stockouts/update',
    }),
    async getData(){
      this.cardloading = true
      let data = {}
      if(this.formStatus==1) {
        // edit
        data = await this.dispatchShow(this.id)
        const {user_create,user_update,user_approval} = data
        this.inputInfo = {user_create,user_update,user_approval}
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.date = data.date
        this.note = data.note
        this.code = data.code
        this.details = data.stock_out_details.map((n) => {
          let item = null
          let unit = null
          if(n.id_item){
            item = {value:n.id_item, label:n.name_item}
          }
          if(n.id_unit){
            unit = {value:n.id_unit, label:n.name_unit}
          }
          return Object.assign({},n,{item,unit})
        })
        this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
        /*
        for (let i = 0; i < this.details.length; i++) {
          const e = this.details[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_item,label:e.name_item}])
            this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
          })
        }
        */
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addRow()
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_branch,branch_name,date,note,details} = this
      let params = {id,id_branch,branch_name,date,note}
      params.total_sale = this.totalSale
      params.total_waste = this.totalWaste
      params.total_employee = this.totalEmployee
      params.total_other = this.totalOther
      params.grand_total = this.grandTotal
      params.details = _.map(details,(n) => {
        let newobject = {}
        if(n.item) {
          newobject.id_item = n.item.value
          newobject.name_item = n.item.label
        }
        if(n.unit) {
          newobject.id_unit = n.unit.value
          newobject.name_unit = n.unit.label
        }
        return Object.assign({},n,newobject)
      })
      if(params.grand_total<=0) {
        this.isloading = false
        return this.toastWarning("Maaf", "Jumlah Grand Total yang anda masukkan 0!")
      }
      if(this.formStatus==0) delete params.id
      try {
        let data = {}
        if(this.formStatus==0) data = await this.dispatchStore(params)
        else data = await this.dispatchUpdate(params)
        if (data.data.err.length>0) {
          let err = `<p class="text-weight-bold" style="font-size: 20px; font-weight: bold;">Stok Keluar dengan kode ${data.data.code} belum terverifikasi karena terdapat permasalahan berikut :</p> <br>`
          for (let i = 0; i < data.data.err.length; i++) {
            const e = data.data.err[i];
            err+=`${i+1}. ${e}<br>`
          }
          err+=`<br><p class="text-weight-bold" style="font-size: 20px; font-weight: bold;">Klik tombol Ya untuk menutup pesan ini</p>`
          // console.log(err)
          // this.toastSuccess("OK",err)
          this.$swal({
            title: `Peringatan`,
            html: err,
            icon: `warning`,
            showCancelButton: false,
            confirmButtonText:`Ya`,
            width: '30%',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        } else {
          this.toastSuccess("OK","Data berhasil disimpan!")
        }
        this.$router.push({name:'stockouts'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    addRow(){
      const data = {
        item:null,
        id_item: null,
        name_item: "",
        unit: null,
        id_unit: null,
        name_unit: "",
        note: "",
        qty_sale: 0,
        qty_waste: 0,
        qty_employee: 0,
        qty_other: 0,
        qty_total: 0
      }
      this.details.push(data)
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    rowItemChange(e,row){
      // console.log(e)
      let tr = this.details[row]
      if(e){
        // console.log(e.item)
        tr.id_item = e.value
        tr.name_item = e.label
      }
      tr.unit = null
      tr.id_unit = null
    },
    rowUnitChange(e,row){
      let tr = this.details[row]
      tr.id_unit = e.value
      tr.name_unit = e.label
    },
    calculateRowTotal(row){
      const {qty_sale, qty_waste, qty_employee, qty_other} = this.details[row]
      this.details[row].qty_total = qty_sale+qty_waste+qty_employee+qty_other
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style lang="scss">
  .swal2-content {
    line-height: 1.5;
    text-align: left;
  }
</style>