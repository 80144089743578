const types = [
  { value: 1, label: "Transfer In" },
  { value: 2, label: "Pembelian" },
];
const statuses = [
  { value: 1, label: "Belum Terferivikasi" },
  { value: 2, label: "Terferivikasi" },
];
const detailDefault = {
  id_items: null,
  item_name: "",
  qty: 1,
  id_unit: null,
  name_unit: "",
  note: "",
};

const statusTable = {
  '1': {
    name: "Belum Ter-verifikasi",
    color: "secondary",
    icon: "",
  },
  '2': {
    name: "Ter-verifikasi",
    color: "success",
    icon: "CheckCircleIcon",
  },
  '3': {
    name: "Create dalam antrian",
    color: "warning",
    icon: "",
  },
  '4': {
    name: "Create dalam edit",
    color: "warning",
    icon: "",
  },
  '5': {
    name: "Hapus dalam edit",
    color: "warning",
    icon: "",
  },
  '6': {
    name: "Create gagal",
    color: "danger",
    icon: "",
  },
  '7': {
    name: "Edit gagal",
    color: "danger",
    icon: "",
  },
  '8': {
    name: "Hpaus gagal",
    color: "danger",
    icon: "",
  },
};

export default {
  types,
  statuses,
  statusTable,
  detailDefault
};
